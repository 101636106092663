import React, { useEffect, useRef } from "react";
import {
  addFileRequest,
  addFileRequestToInternal,
  addFolderRequest,
  addInternalFolderRequest,
} from "Config/API/action";
import {
  folderData,
  getInternalFolder,
  getOrganizationConnection,
  getUserPermissions,
} from "Config/API/get";
import {
  internalFolderDataAction,
  selectedFileDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
  storeFolderDataAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

import AddWebLinkToFileExplorer from "Components/Popup/AddWebLink/AddWebLinkToFileExplorer";
import Directory from "../PureComponents/Directory";
import RenderPopup from "Components/Popup/FolderPopup/Container/RenderPopup";
import RenderQRPopup from "Components/Popup/QRcodePopup/Container/RenderQRcodePopup";
import ShareExternalFolderPopup from "Components/Popup/ShareFolderPopup/ShareExternalFolderPopup";
import ShareFolderPopup from "Components/Popup/ShareFolderPopup/ShareFolderPopup";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import fileIcon from "Media/icons/material-create-new-folder.svg";
import { globalOptionButtonAction } from "Redux/action/variable.action";
import qrcode from "Media/icons/metro-qrcode.svg";
import shareIcon from "Media/icons/material-share.svg";
import { toast } from "react-toastify";
import uploadIcon from "Media/icons/awesome-file-upload.svg";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { useState } from "react";
import webLink from "Media/icons/webLink.svg";

const RenderDirectory = () => {
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [organizationData, setOrganizationData] = useState({});
  const [popup, setPopup] = useState(false);
  const [showAddWebLink, setShowAddWebLink] = useState(false);
  const [qrPopup, setQrPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [internalFolderData, setInternalFolderData] = useState([]);
  const [showShareExternalFolderPopup, setShowShareExternalFolderPopup] =
    useState(false);
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const isStorageLimitReached = useIsStorageLimitReached();
  const role = useSelector((state) => state.authenticationReducer?.role);
  const [usersAllPermissions, setUsersAllPermissions] = useState([]);
  let files = useSelector((state) => state.selectedFileDataReducer);
  let internalFolder = useSelector((state) => state.internalFolderDataReducer);
  const addFolderData = useSelector((state) => state.selectedFolderDataReducer);
  console.log("addfolderdata", addFolderData);
  const selectedOrgLogo = useSelector(
    (state) => state.currentVisitOrgReducer?.profiledetail?.logo
  );
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);

  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);
  const baseURL = parsedURL.protocol + "//" + parsedURL.hostname;
  const toastId = useRef(null);
  const qrCodeUrl = addFolderData?.sharePath
    ?.toString()
    ?.replace(
      baseURL,
      `${baseURL}/amnius-connect/api/deep-link?url=amniusconnect://app`
    );

  if (
    files === undefined ||
    files === null ||
    files === "" ||
    files?.length === 0
  ) {
    files = {};
  }
  if (
    organizationData === undefined ||
    organizationData === null ||
    organizationData === "" ||
    organizationData?.length === 0
  ) {
    setOrganizationData({});
  }

  if (internalFolder !== "") {
    files = internalFolder;
  }

  // refs ***********************
  const fileRef = useRef(null);
  useOutsideAlerter(fileRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(globalOptionButtonAction(""));
        } else {
          if (ref.current !== null) {
          } else {
            dispatch(globalOptionButtonAction(""));
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    const usersAllPermissions = getUserPermissions(token);
    usersAllPermissions.then((res) => {
      if (res?.status) {
        setUsersAllPermissions(res?.data?.emp);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddFolderAndFileInternallySuccess = (
    array,
    selectedFolderUniqueId
  ) => {
    // eslint-disable-next-line array-callback-return
    array.map((item) => {
      if (item?.unique_id === selectedFolderUniqueId) {
        dispatch(selectedFolderDataAction(item));
        dispatch(internalFolderDataAction(item?.items));
      } else
        onAddFolderAndFileInternallySuccess(
          item?.items,
          selectedFolderUniqueId
        );
    });
  };

  const onAddFolderAndFileSharedSuccess = (array, selectedFolderUniqueId) => {
    // eslint-disable-next-line array-callback-return
    array.map((item) => {
      if (item?.unique_id === selectedFolderUniqueId) {
        dispatch(selectedFolderDataAction(item));
        dispatch(selectedFileDataAction(item?.items));
      } else
        onAddFolderAndFileSharedSuccess(item?.items, selectedFolderUniqueId);
    });
  };

  // adding a folder
  const addAFolder = (folderName) => {
    toast("Uploading, Please Wait...", { type: "info" });

    const addFolderStatus = addFolderRequest(
      addFolderData?.conn_id,
      folderName,
      addFolderData?.unique_id,
      token
    );
    addFolderStatus
      .then((res) => {
        if (res?.status) {
          const folderDataApiResponse = folderData(token);
          folderDataApiResponse.then((res) => {
            toast("Uploaded!", { type: "success" });
            onAddFolderAndFileSharedSuccess(
              res?.result,
              addFolderData?.unique_id
            );
            dispatch(storeFolderDataAction(res?.result));
            // eslint-disable-next-line array-callback-return
            res?.result?.map((item) => {
              if (item?.conn_id === addFolderData?.conn_id)
                dispatch(selectedFolderRootDataAction(item));
            });
          });
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch(() =>
        toast("Failed! Try again later", {
          type: "error",
        })
      );
  };
  // if adding internal folder
  const addAInternalFolder = (folderName) => {
    toast("Uploading, Please Wait...", { type: "info" });
    const addFolderStatus = addInternalFolderRequest(
      folderName,
      addFolderData?.unique_id,
      token
    );
    addFolderStatus
      .then((res) => {
        if (res?.status) {
          const internalFolderStatus = getInternalFolder(token);
          internalFolderStatus.then((res) => {
            toast("Uploaded!", { type: "success" });
            onAddFolderAndFileInternallySuccess(
              res?.result,
              addFolderData?.unique_id
            );
            dispatch(selectedFolderRootDataAction(res?.result));
          });
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch(() =>
        toast("Failed! Try again later", {
          type: "error",
        })
      );
  };

  // adding files
  const addAFile = async (uploadFile) => {
    setUploadButtonDisable(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });

    try {
      const res = await addFileRequest(
        addFolderData?.key,
        addFolderData?.conn_id,
        addFolderData?.unique_id,
        uploadFile,
        token,
        { current: toastId } // Pass the toastId as an object
      );

      if (res?.status) {
        if (res?.message?.includes("limit")) {
          return toast(res?.message, { type: "info" });
        }

        const folderDataResponse = await folderData(token);
        toast.update(toastId, {
          render: "Uploaded!",
          type: "success",
          autoClose: 4000,
        });

        onAddFolderAndFileSharedSuccess(
          folderDataResponse?.result,
          addFolderData?.unique_id
        );
        dispatch(storeFolderDataAction(folderDataResponse?.result));
      } else {
        toast(res?.message, { type: "error" });
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast("Failed! Try again later", { type: "error" });
    } finally {
      setUploadButtonDisable(false);
    }
  };

  // If adding files to internal folders
  const addAFileToInternal = async (uploadFile) => {
    setUploadButtonDisable(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });

    try {
      const res = await addFileRequestToInternal(
        addFolderData?.key,
        addFolderData?.unique_id,
        uploadFile,
        token,
        { current: toastId } // Pass the toastId as an object
      );

      if (res?.status) {
        if (res?.message?.includes("limit")) {
          return toast(res?.message, { type: "info" });
        }

        const internalFolderResponse = await getInternalFolder(token);
        toast.update(toastId, {
          render: "Uploaded!",
          type: "success",
          autoClose: 4000,
        });

        onAddFolderAndFileInternallySuccess(
          internalFolderResponse?.result,
          addFolderData?.unique_id
        );
      } else {
        toast(res?.message, { type: "error" });
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast("Failed! Try again later", { type: "error" });
    } finally {
      setUploadButtonDisable(false);
    }
  };

  const getInternalFolderData = () => {
    setLoader();
    const internalFolderStatus = getInternalFolder(token);
    internalFolderStatus.then((res) => {
      resetLoader();
      if (res.status) {
        setShowShareExternalFolderPopup(true);
        setInternalFolderData(res?.result[0]);
      } else {
        toast("Failed! Try again later", {
          type: "error",
        });
      }
    });
  };

  return (
    <>
      {Object.keys(files).length?.toString() === "0" ? (
        <div className="file_explorer_single_folder">
          <p>This folder is empty.</p>
        </div>
      ) : (
        <div className="file_explorer_single_folder" ref={fileRef}>
          {files?.map((curr, index) => (
            <Directory
              files={curr}
              key={index}
              usersAllPermissions={usersAllPermissions}
            />
          ))}
        </div>
      )}

      {/* we are checking the base folder or not */}
      {files[0]?.location ? null : (
        <>
          {addFolderData &&
          (role?.toString() === "admin" ||
            usersAllPermissions?.permission_write_rights?.toString() ===
              "write" ||
            usersAllPermissions?.permission_file_manager?.toString() ===
              "write") ? (
            <div className="file_explorer_options">
              {addFolderData?.location
                ? null
                : (usersAllPermissions?.permission_file_manager?.toString() === "write" ||
                usersAllPermissions?.permission_write_rights?.toString() === "write")  && (
                    <>
                      <div onClick={() => setQrPopup(true)}>
                        <img src={qrcode} width="20px" alt="QR code" />
                        <p>Create Folder QR Code</p>
                      </div>
                      {qrPopup ? (
                        <>
                          <RenderQRPopup
                            selectedOrgLogo={selectedOrgLogo}
                            folderData={addFolderData}
                            internalFolder={internalFolder}
                            hide={setQrPopup}
                            path={qrCodeUrl}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                {(usersAllPermissions?.permission_file_manager?.toString() === "write" ||
  usersAllPermissions?.permission_write_rights?.toString() === "write")  && (
                <div onClick={() => setPopup(true)}>
                  <img src={fileIcon} width="21px" alt="Add Folder" />
                  <p>Add Folder</p>
                </div>
              )}
              {popup ? (
                <RenderPopup
                  hide={setPopup}
                  verify={
                    internalFolder === "" ? addAFolder : addAInternalFolder
                  }
                />
              ) : null}

              {(usersAllPermissions?.permission_file_manager?.toString() ===
                "write" ||
                usersAllPermissions?.permission_write_rights?.toString() ===
                  "write") && (
                <div>
                  <label htmlFor="uploadFile">
                    <img src={uploadIcon} width="15px" alt="Upload File" />
                    <p>Upload File</p>
                  </label>
                  <input
                    type="file"
                    id="uploadFile"
                    hidden
                    disabled={uploadButtonDisable}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (!file) return; // Ensure a file was selected

                      const size = file.size;
                      if (isStorageLimitReached(size)) {
                        setShowStorageLimitReachedPopup(true);
                        return;
                      }

                      if (internalFolder === "") {
                        addAFile(file);
                      } else {
                        addAFileToInternal(file);
                      }

                      // Reset the file input value to allow for re-uploading the same file
                      e.target.value = "";
                    }}
                    style={{
                      pointerEvents: uploadButtonDisable ? "none" : "auto",
                    }} // Adjust pointer events based on disable state
                  />
                </div>
              )}

              {(usersAllPermissions?.permission_file_manager?.toString() ===
                "write" ||
                usersAllPermissions?.permission_write_rights?.toString() ===
                  "write") && (
                <div onClick={() => setShowAddWebLink(true)}>
                  <img src={webLink} width="18px" alt="add web link" />
                  <p>Add Web Link</p>
                </div>
              )}

              {addFolderData?.location
                ? null
                : (usersAllPermissions?.permission_file_manager?.toString() ===
                    "write" ||
                    usersAllPermissions?.permission_write_rights?.toString() ===
                      "write") && (
                    <>
                      <div
                        onClick={() => {
                          if (internalFolder !== "") {
                            return setShowSharePopup(true);
                          } else {
                            getInternalFolderData();
                          }
                        }}
                      >
                        <img src={shareIcon} width="16px" alt="Share Folder" />
                        <p>Share</p>
                      </div>
                    </>
                  )}
            </div>
          ) : null}
          <div style={{ position: "absolute", left: 0 }}>
            {showStorageLimitReachedPopup && (
              <StorageLimitReachedPopup
                hide={setShowStorageLimitReachedPopup}
              />
            )}
            {showAddWebLink && (
              <AddWebLinkToFileExplorer
                hide={setShowAddWebLink}
                isInternal={internalFolder !== ""}
                parent_unique_id={addFolderData?.unique_id}
                conn_id={addFolderData?.conn_id}
              />
            )}
            {showSharePopup && (
              <ShareFolderPopup
                hide={setShowSharePopup}
                token={token}
                selectedFolderId={addFolderData?.unique_id}
              />
            )}
            {showShareExternalFolderPopup && (
              <ShareExternalFolderPopup
                hide={setShowShareExternalFolderPopup}
                token={token}
                selectedFolderId={addFolderData?.unique_id}
                internalFolder={internalFolderData}
              />
            )}
          </div>
        </>
      )}
      {loader}
    </>
  );
};

export default RenderDirectory;
