import "./QRcodePopup.css";

import React, { useRef } from "react";

import { Button } from "@mui/material";
import QRcode from "Components/FileExplorer/QRcode/QRcode";
import amniusLogo from "Media/Amnius_connect_with_text.png";
import blank from "Media/blank-profile-picture.png";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useReactToPrint } from "react-to-print";
 
 
const Popup = ({ path, hide, internalFolder, folderData, selectedOrgLogo }) => {
  const generalInfo = useSelector((state) => state.renderGeneralInfo);
  const componentRef = useRef();
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  const renderHeader = () => {
    if (internalFolder === "")
      return (
        <>
          <div className="qr-title">{folderData?.name?.substr(0, 20)}</div>
          <div
            style={{ fontWeight: "bold", textTransform: "uppercase" }}
            className="qr-code-connected-folder"
          >
            Connected Folder
          </div>
        </>
      );
    else
      return (
        <>
          <div className="qr-title">{folderData?.name?.substr(0, 20)}</div>
          <div
            style={{ fontWeight: "bold", textTransform: "uppercase" }}
            className="qr-code-connected-folder"
          >
            Internal Folder
          </div>
        </>
      );
  };

  const renderImage = () => {
    if (internalFolder === "")
      return (
        <div style={{ display: "flex", gap: "8px", marginTop: "15px" }}>
          <img
            src={generalInfo?.logo ? generalInfo?.logo : blank}
            alt="organisaiton logo"
          />
          <img
            src={selectedOrgLogo ? selectedOrgLogo : blank}
            alt="organisaiton logo"
          />
        </div>
      );
    else
      return (
        <div style={{ display: "flex", gap: "8px", marginTop: "15px" }}>
          <img
            src={generalInfo?.logo ? generalInfo?.logo : blank}
            alt="organisaiton logo"
          />
        </div>
      );
  };

  // download png file
  const handleDownloadImage = async () => {
    // toPng(componentRef.current, { cacheBust: true })
    //   .then((dataUrl) => {
      html2canvas(componentRef.current, { useCORS: true },{ cacheBust: true })
      .then((canvas) => {

        const dataUrl = canvas.toDataURL("image/png");    //changed 
        const link = document.createElement("a");
        link.download = "FolderQr.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        toast(err, {
          type: "error",
        });
      });
  };
  // const handlePrint = useReactToPrint({               
  //   content: () => componentRef.current,
  // });
 
  return (
    <>
      <nav className="qr-container">
        <div className="qr-popup">
          <div className="qr-cross" onClick={() => hide(false)}>
            X
          </div>
          <div className="qr-body" ref={componentRef}>
            <QRcode path={path} />
            {renderHeader()}
            {renderImage()}
            <div className="qr-row">
              <div className="qr-title">POWERED BY:</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={amniusLogo} alt="" className="amnius-logo" />
              </div>
            </div>
          </div>

          <div className="qr-print-btn">
            <Button
              variant="contained"
              style={{ background: "#446FA2" }}
              onClick={handleDownloadImage}
            >
              DOWNLOAD
            </Button>
          </div>
        </div>
        {loader}
      </nav>
    </>
  );
};

export default Popup;
