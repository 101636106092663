import "./TabContent.css";

import React, { useEffect, useRef, useState } from "react";
import {
  changeCategoryScope,
  editCategoryFunction,
  editLocationFunction,
} from "Config/API/action";
import {
  getCategoryData,
  getLocationData,
  getUserPermissions,
  renderTeam,
} from "Config/API/get";

import { Button } from "@mui/material";
import DownIcon from "Media/icons/ionic-md-arrow-dropdown.svg";
import ExternalIcon from "Media/icons/External_icon.svg";
import RenderCard from "../Cards/ProfileCard/Container/RenderProfileCard";
import RenderConfirm from "../Popup/Option/Container/RenderConfirm";
import RenderPopup from "../Popup/Entry/Container/RenderPopup";
import options from "Media/icons/feather-more-vertical.svg";
import { toast } from "react-toastify";

const LocationDropDown = ({
  locationTabLinks,
  changeLocationTab,
  role,
  setLocationOptions,
  locationOptions,
}) => {

  return (
    <>
      <div className="category-dropdown-list">
        {locationTabLinks?.length > 0
          ? locationTabLinks.map((currEle, index) => {
              return (
                <div className="tablinks" key={index}>
                  <button
                    className="locationlinks-button"
                    onClick={(e) => changeLocationTab(e, currEle?.id)}
                  >
                    {currEle?.location}
                  </button>
                  {(role?.toString() === "admin" || role?.toString() === "super_admin") ? (
                    <img
                      src={options}
                      alt="options"
                      onClick={() => {
                        setLocationOptions(currEle?.id);
                        if (
                          currEle?.id?.toString() ===
                          locationOptions?.toString()
                        ) {
                          setLocationOptions("a");
                        }
                      }}
                    />
                  ) : null}
                  {currEle?.id?.toString() === locationOptions?.toString() ? (
                    <LocationDropDownMenu location_id={currEle?.id} />
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};

const CategoryDropDown = ({
  categoryTabLinks,
  role,
  setCategoryOptions,
  categoryOptions,
  changeCategoryTab,
}) => {
  return (
    <>
      <div className="category-dropdown-list">
        {categoryTabLinks?.length > 0
          ? categoryTabLinks.map((currEle, index) => {
              return (
                <div className="tablinks" key={index}>
                  <button
                    className="tablinks-button"
                    onClick={(e) =>
                      changeCategoryTab(e, "", currEle?.id, currEle?.org_id)
                    }
                  >
                    {currEle?.scope?.toString() === "external" ? (
                      <img src={ExternalIcon} alt="External" />
                    ) : null}

                    {currEle?.category_id}
                  </button>

                  {(role?.toString() === "admin" || role?.toString() === "super_admin")? (
                    <img
                      src={options}
                      alt="options"
                      onClick={() => {
                        setCategoryOptions(currEle?.category_id);
                        if (
                          currEle?.category_id?.toString() ===
                          categoryOptions?.toString()
                        ) {
                          setCategoryOptions("");
                        }
                      }}
                    />
                  ) : null}

                  {currEle?.category_id?.toString() ===
                  categoryOptions?.toString() ? (
                    <DropDownMenu
                      category_id={currEle?.id}
                      scope={currEle?.scope}
                    />
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </>
  );
};

const DropDownMenu = ({ category_id, scope }) => {
  const token = localStorage.getItem("token");
  const optionRef = useRef(null);
  const [optionPopup, setOptionPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);

  useOutsideAlerter(optionRef);
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        ref.current.classList.add("hide-dropdown");
      } else {
        if (ref.current !== null) {
          ref.current.classList.remove("hide-dropdown");
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  // function to set category scope
  const setCategory = (inputScope) => {
    if (scope?.toString() !== inputScope?.toString()) {
      const category_status = changeCategoryScope(
        token,
        category_id,
        inputScope
      );
      category_status.then((res) => {
        if (res?.status) {
          toast(res?.message, {
            type: "success",
          });
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
        window.location.reload();
      });
    } else {
      toast(`Scope is already set as ${scope}`, { type: "info" });
    }
  };

  // function to edit category
  const editCategory = (categoryText) => {
    const edit_status = editCategoryFunction(token, category_id, categoryText);
    edit_status.then((res) => {
      if (res?.status) {
        toast(res?.message, {
          type: "success",
        });
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
      window.location.reload();
    });
  };

  return (
    <>
      <div className="dropdown-menu" ref={optionRef}>
        <ul>
          <li
            onClick={() => {
              setOptionPopup(true);
            }}
          >
            Set Category Scope
          </li>
          <li
            onClick={() => {
              setEditPopup(true);
            }}
          >
            Edit Category
          </li>
        </ul>
      </div>

      {/* Popup of category scope */}
      {optionPopup ? (
        <RenderConfirm
          message={"Set category scope as: "}
          option1={"internal"}
          option2={"external"}
          funct={setCategory}
          hide={setOptionPopup}
        />
      ) : null}

      {/* Popup of category edit */}
      {editPopup ? (
        <RenderPopup
          verify={editCategory}
          message={"Update Category Name:"}
          hide={setEditPopup}
        />
      ) : null}
    </>
  );
};

const LocationDropDownMenu = ({ location_id }) => {
  const token = localStorage.getItem("token");
  const locationOptionRef = useRef(null);
  const [editLocationPopup, setEditLocationPopup] = useState(false);

  useOutsideAlerter(locationOptionRef);
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        ref.current.classList.add("hide-dropdown");
      } else {
        if (ref.current !== null) {
          ref.current.classList.remove("hide-dropdown");
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  // function to edit location
  const editLocation = (locationText) => {
    const edit_status = editLocationFunction(token, location_id, locationText);
    edit_status.then((res) => {
      if (res?.status) {
        toast(res?.message, {
          type: "success",
        });
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
      window.location.reload();
    });
  };

  return (
    <>
      <div className="dropdown-menu" ref={locationOptionRef}>
        <ul>
          <li
            onClick={() => {
              setEditLocationPopup(true);
            }}
          >
            Edit Location
          </li>
        </ul>
      </div>

      {/* Popup of location edit */}
      {editLocationPopup ? (
        <RenderPopup
          verify={editLocation}
          message={"Update Location Name:"}
          hide={setEditLocationPopup}
        />
      ) : null}
    </>
  );
};

const TabContent = ({ role, setOpenAddUser, general_info, state }) => {
  const token = localStorage.getItem("token");
  const [tabLinks, setTabLinks] = useState([]);
  const [categoryTabLinks, setCategoryTabLinks] = useState([]);
  const [locationTab, setLocationTab] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [locationTabLinks, setLocationTabLinks] = useState([]);
  const [selectedCategoryTabData, setSelectedCategoryTabData] = useState({});
  const [selectedLocationTabData, setSelectedLocationTabData] = useState({});
  const [downActive, setDownActive] = useState(false);
  const [downLocationActive, setDownLocationActive] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState("");
  const [locationOptions, setLocationOptions] = useState("");
  const [usersAllPermissions, setUsersAllPermissions] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const loadingArray = ["", ""];

  // using ref for dropdown categories
  const dropdownRef = useRef(null);
  // using ref for dropdown Locations
  const dropdownLocationRef = useRef(null);
  useOutsideAlerter(dropdownRef);
  useOutsideAlerter(dropdownLocationRef);
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        ref.current.classList.add("hide-dropdown");
      } else {
        if (ref.current !== null) {
          ref.current.classList.remove("hide-dropdown");
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  // getting all the categories from api
  useEffect(() => {
    getAllLocations();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general_info]);

  useEffect(() => {
    const usersAllPermissions = getUserPermissions(token);
    usersAllPermissions.then((res) => {
      if (res?.status) {
        setUsersAllPermissions(res?.data?.emp);
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllCategories = (location_id, isAutomatic) => {
    setLocationId(location_id);
    const categoryList = getCategoryData(token, general_info?.id, location_id);
    categoryList.then((res) => {
      if (isAutomatic) {
        const locationlinks = document.getElementsByClassName(
          "locationlinks-button"
        );
        locationlinks[0]?.click();
        return;
      }
      const arrayLength = res?.category?.length;
      if (arrayLength === 0) {
        setTabLinks([]);
        setCategoryTabLinks([]);
        setTeamMembers([]);
        setCategoryLoading(false);
        return setMemberLoading(false);
      }
      const arrayWith2 = [];
      const arrayWithRest = [];

      // spliting data for category dropdown
      if (arrayLength > 2) {
        for (let i in res?.category) {
          if (i < 2) {
            arrayWith2.push(res?.category[i]);
          } else {
            arrayWithRest.push(res?.category[i]);
          }
        }
        setTabLinks(arrayWith2);
        setCategoryTabLinks(arrayWithRest);
      } else {
        setTabLinks(res?.category);
        setCategoryTabLinks([]);
      }
      setCategoryLoading(false);

      // logic for default condition
      if (arrayLength > 0) {
        const get_teams = renderTeam(
          token,
          res.category[0]?.id,
          res.category[0]?.org_id,
          location_id
        );
        get_teams.then((res) => {
          setMemberLoading(false);
          if (res?.status) {
            setTeamMembers(res?.update);

            //  click first button automatically
            const tablinks = document.getElementsByClassName("tablinks-button");
            tablinks[0]?.click();
          }
        });
      }
    });
  };

  const getAllLocations = () => {
    setLocationLoading(true);
    setCategoryLoading(true);
    setMemberLoading(true);
    const locationList = getLocationData(token, general_info?.id);
    locationList.then((res) => {
      const arrayLength = res?.location?.length;
      if (arrayLength === 0) {
        setLocationTab([]);
        setLocationTabLinks([]);
        setTabLinks([]);
        setCategoryTabLinks([]);
        setTeamMembers([]);
        setLocationLoading(false);
        setCategoryLoading(false);
        return setMemberLoading(false);
      }
      const arrayWith2 = [];
      const arrayWithRest = [];

      // spliting data for category dropdown
      if (arrayLength > 2) {
        for (let i in res?.location) {
          if (i < 2) {
            arrayWith2.push(res?.location[i]);
          } else {
            arrayWithRest.push(res?.location[i]);
          }
        }
        setLocationTab(arrayWith2);
        setLocationTabLinks(arrayWithRest);
      } else {
        setLocationTab(res?.location);
        setLocationTabLinks([]);
      }
      setLocationLoading(false);

      // logic for default condition
      if (arrayLength > 0) {
        getAllCategories(res.location[0]?.id, true);
      }
    });
  };

  // onClick load users
  const openCity = (event, cityName) => {
    let i, tablinks;
    tablinks = document.getElementsByClassName("tablinks-button");

    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    document.getElementById(cityName).style.display = "block";
    event.currentTarget.className += " active";
  };

  const changeCategoryTab = (e, from, categoryId, orgId) => {
    if (
      selectedCategoryTabData?.categoryId === categoryId &&
      selectedCategoryTabData?.orgId === orgId &&
      from?.length === 0
    )
      return;
    setMemberLoading(true);
    setSelectedCategoryTabData({
      categoryId: categoryId,
      orgId: orgId,
    });
    const get_teams = renderTeam(token, categoryId, orgId, locationId);
    get_teams.then((res) => {
      if (res?.status) {
        setTeamMembers(res?.update);
      }
      setMemberLoading(false);
    });
    if (from?.length === 0) openCity(e, "team-data");
  };

  const changeLocationTab = (e, location_id) => {
    if (selectedLocationTabData?.location_id === location_id) return;
    setCategoryLoading(true);
    setMemberLoading(true);
    setSelectedLocationTabData({
      location_id: location_id,
    });
    setSelectedCategoryTabData({});
    getAllCategories(location_id, false);
    let i, tablinks;
    tablinks = document.getElementsByClassName("locationlinks-button");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    e.currentTarget.className += " active";
  };

  return (
    <>
      <div className="profile-text-wrapper">
        <div className="profile-banner">LOCATIONS</div>
      </div>
      {/* LIST OF LOCATIONS */}
      <div className="tab">
        {!locationLoading
          ? locationTab &&
            locationTab?.map((currEle, index) => {
              return (
                <div className="tablinks" key={index}>
                  <button
                    className="locationlinks-button"
                    onClick={(e) => changeLocationTab(e, currEle?.id)}
                  >
                    {currEle?.location}
                  </button>
                  {(role?.toString() === "admin" || role?.toString() === "super_admin")  ? (
                    <img
                      src={options}
                      alt="options"
                      onClick={() => {
                        setLocationOptions(currEle?.id);
                        if (
                          currEle?.id?.toString() ===
                          locationOptions?.toString()
                        ) {
                          setLocationOptions("");
                        }
                      }}
                    />
                  ) : null}
                  {currEle?.id?.toString() === locationOptions?.toString() ? (
                    <LocationDropDownMenu location_id={currEle?.id} />
                  ) : null}
                </div>
              );
            })
          : loadingArray.map((item, index) => (
              <div className="tablinks" key={index}>
                <button
                  className="animation"
                  style={{ height: "30px", width: "90px" }}
                />
              </div>
            ))}

        <div
          className="category-dropdown dropdown-arrow-btn"
          ref={dropdownLocationRef}
        >
          {/* Category Drown Down Menu */}
          {!locationLoading ? (
            locationTabLinks?.length > 0 && (
              <button
                className="locationlinks-button"
                onClick={() => {
                  setDownLocationActive(!downLocationActive);
                  setLocationOptions("");
                }}
              >
                <img src={DownIcon} alt="DownKey" />
              </button>
            )
          ) : (
            <div className="tablinks">
              <button
                className="animation"
                style={{ height: "30px", width: "60px" }}
              />
            </div>
          )}

          {downLocationActive ? (
         
            <LocationDropDown
              locationTabLinks={locationTabLinks}
              changeLocationTab={changeLocationTab}
              role={role}
              locationOptions={locationOptions}
              setLocationOptions={setLocationOptions}
            />
          ) : null}
        </div>
      </div>

      <div className="profile-text-wrapper" style={{ marginTop: "15px" }}>
        <div className="profile-banner">USER GROUPS</div>
        {role?.toString() !== "other" &&
        (role?.toString() === "admin" ||
          usersAllPermissions?.permission_user_manager?.toString() ===
            "write" ||
          role?.toString() === "super_admin") ? (
          <div className="profile-add">
            <Button
              variant="contained"
              style={{ background: "#5ca127" }}
              onClick={() => {
                setOpenAddUser(true);
              }}
            >
              +ADD USER
            </Button>
          </div>
        ) : null}
      </div>

      {/* LIST OF CATEGORIES */}
      <div className="tab">
        {!categoryLoading
          ? tabLinks &&
            tabLinks?.map((currEle, index) => {
              return (
                <div className="tablinks" key={index}>
                  <button
                    className="tablinks-button"
                    onClick={(e) =>
                      changeCategoryTab(e, "", currEle?.id, currEle?.org_id)
                    }
                  >
                    {currEle?.scope?.toString() === "external" ? (
                      <img src={ExternalIcon} alt="External" />
                    ) : null}

                    {currEle?.category_id}
                  </button>

                  {(role?.toString() === "admin" || role?.toString() === "super_admin")  ? (
                    <img
                      src={options}
                      alt="options"
                      onClick={() => {
                        setCategoryOptions(currEle?.category_id);
                        if (
                          currEle?.category_id?.toString() ===
                          categoryOptions?.toString()
                        ) {
                          setCategoryOptions("");
                        }
                      }}
                    />
                  ) : null}

                  {currEle?.category_id?.toString() ===
                  categoryOptions?.toString() ? (
                    <DropDownMenu
                      category_id={currEle?.id}
                      scope={currEle?.scope}
                    />
                  ) : null}
                </div>
              );
            })
          : loadingArray.map((item, index) => (
              <div className="tablinks" key={index}>
                <button
                  className="animation"
                  style={{ height: "30px", width: "120px" }}
                />
              </div>
            ))}

        <div className="category-dropdown dropdown-arrow-btn" ref={dropdownRef}>
          {/* Category Drown Down Menu */}
          {!categoryLoading ? (
            categoryTabLinks?.length > 0 && (
              <button
                className="tablinks-button "
                onClick={() => {
                  setDownActive(!downActive);
                  setCategoryOptions("");
                }}
              >
                <img src={DownIcon} alt="DownKey" />
              </button>
            )
          ) : (
            <div className="tablinks">
              <button
                className="animation"
                style={{ height: "30px", width: "60px" }}
              />
            </div>
          )}

          {downActive ? (
            <CategoryDropDown
              categoryTabLinks={categoryTabLinks}
              role={role}
              setCategoryOptions={setCategoryOptions}
              categoryOptions={categoryOptions}
              changeCategoryTab={changeCategoryTab}
            />
          ) : null}
        </div>
      </div>

      {/* USER PER CATEGORIES */}
      <div id="team-data">
        <div className="profile-card-wrapper">
          {!memberLoading ? (
            teamMembers?.length > 0 ? (
              teamMembers?.map((currEle) => {
                return (
                  <RenderCard
                    state={state}
                    currEle={currEle}
                    role={role}
                    key={currEle.id}
                    usersAllPermissions={usersAllPermissions}
                    selectedCategoryTabData={selectedCategoryTabData}
                    changeCategoryTab={changeCategoryTab}
                  />
                );
              })
            ) : (
              <p>No User Found</p>
            )
          ) : (
            loadingArray.map((item, index) => (
              <div key={index} className="org-card-container">
                <div
                  style={{ marginTop: "7px" }}
                  className="org-card-board"
                ></div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default TabContent;
